import React, { useState } from 'react'
import { FunctionsList } from 'components'
import { useIsSmallScreen } from 'packages/core'
import { Box } from '@mui/material'
import { Pagination, ThresholdSearchInput } from 'packages/eid-ui'
import { useManagementRoleLocalFunctions } from 'hooks'

const take = 10

export const LocalSensitiveFunctions = ({ managementRole }: any) => {
    const isSmallScreen = useIsSmallScreen()

    const [page, setPage] = React.useState(1)
    const [perPageItems, setPerPageItems] = useState(take)
    const [searchKey, setSearchKey] = useState('')

    const handlePageChange = (_: any, value: any) => {
        setPage(value)
    }
    const handlePageSelection = (value: any) => {
        setPage(value)
    }
    const handleItemsPerPageChange = (value: any) => {
        setPage(1)
        setPerPageItems(value)
    }

    const handleSearchChange = (value: any) => {
        setPage(1)
        setSearchKey(value)
    }

    const { latestData } = useManagementRoleLocalFunctions(
        managementRole.id,
        (page - 1) * perPageItems,
        perPageItems,
        searchKey && encodeURIComponent(searchKey),
    )

    const localFunctions = latestData ? latestData.data : undefined

    const globalFunctionsLoading = !Boolean(latestData)

    const numberOfPages = latestData
        ? Math.ceil(latestData.totalCount / perPageItems)
        : 0

    return (
        <>
            <Box
                width="100%"
                display="flex"
                justifyContent="flex-start"
                alignItems="center"
                padding="16px 32px 16px 32px"
                style={{ backgroundColor: '#ebebed' }}
            >
                <Box width={!isSmallScreen ? '308px' : '200px'}>
                    <ThresholdSearchInput
                        placeholder={'Common_Search'}
                        handleSearch={handleSearchChange}
                    />
                </Box>
            </Box>
            <Box overflow="auto">
                <FunctionsList
                    data={localFunctions}
                    loading={globalFunctionsLoading}
                />

                {!globalFunctionsLoading && (
                    <Box
                        padding={!isSmallScreen ? '20px 32px' : '16px'}
                        display="flex"
                        alignItems="center"
                        justifyContent={
                            !isSmallScreen ? 'flex-start' : 'center'
                        }
                    >
                        <Pagination
                            count={numberOfPages}
                            size={!isSmallScreen ? 'large' : 'small'}
                            page={page}
                            onChange={handlePageChange}
                            totalCount={latestData?.totalCount}
                            onPageSelection={handlePageSelection}
                            itemsPerPage={perPageItems}
                            onItemsPerPageChange={handleItemsPerPageChange}
                            showPageSelection={true}
                            showItemsPerPageSelection
                            showCountStat
                        />
                    </Box>
                )}
            </Box>
        </>
    )
}
