import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Typography, useTheme, Dialog, FormControlLabel } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useIsSmallScreen } from 'packages/core'
import { IconButton, EidButton, TextInput, Checkbox } from 'packages/eid-ui'
import { Icon } from 'packages/eid-icons'
import { ItemDetails } from 'components'
import { TimeDurationControl } from 'components/TimeDurationControl'
import { GetDisabledTimeSlots } from 'hooks'
import moment from 'moment'

const overflowStyleProps = {
    maxHeight: '220px',
    overflow: 'auto',
}

const mobileScreenWidth = '767px'

const useStyles = makeStyles((theme) => ({
    body: () => ({
        backgroundColor: '#fbfbfd',
        padding: '24px 40px',
        boxShadow: '0 2px 16px 0 rgba(0, 0, 0, 0.11)',
        '&:focus': {
            outline: 'none',
        },
    }),
    modalHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        height: '80px',
        alignItems: 'center',
        padding: '16px 12px 16px 32px',
        backgroundColor: theme?.palette?.background?.paper,
    },
    closeIcon: {
        display: 'flex',
    },

    title: {
        fontSize: '18px',
        fontWeight: 'bold',
        color: '#3b454d',
    },
    sectionHeader: {
        overflowWrap: 'break-word',
        wordBreak: 'normal',
        textTransform: 'uppercase',
        color: `${theme?.palette?.common?.black} !important`,
        overflow: 'hidden',
        lineHeight: '15px',
        fontSize: '14px',
        padding: '15px 31px',
        borderTop: '1px solid rgba(0, 0, 0, .05)',
        borderBottom: '1px solid rgba(0, 0, 0, 0.05)',
        fontWeight: 'bold',
        boxShadow: '0 2px 16px 0 rgba(0, 0, 0, 0.11)',
        backgroundImage:
            'linear-gradient(0deg, rgb(0, 0, 0, 0.02) 12.5%, rgb(255, 255, 255) 12.5%, rgb(255, 255, 255) 50%, rgb(0, 0, 0, 0.02) 50%, rgb(0, 0, 0, 0.02) 62.5%, rgb(255, 255, 255) 62.5%, rgb(255, 255, 255) 100%)',
        backgroundSize: '8.00px 8.00px',
        '& svg': {
            margin: '0px !important',
        },
    },
    invalidDatesMessage: {
        position: 'absolute',
        fontSize: '12px',
        color: theme?.palette?.error?.main,
        marginRight: '12px',
    },
    footer: {},

    maxDurationCheckbox: {
        display: 'flex',
        alignSelf: 'end',
        '& span': {
            fontSize: '14px',
        },
    },
}))

const useDialogStyles = makeStyles(() => ({
    paper: {
        maxWidth: '1000px',
        width: '840px',
        [`@media (max-width:${mobileScreenWidth})`]: {
            margin: '8px ',
            width: 'calc(100% - 16px) !important',
            maxWidth: 'calc(100% - 16px) !important ',
        },
    },
}))

export interface CheckOutAdvancedProps {
    open?: any
    onClose?: any
    onReset?: any
    onCancel?: any
    onSucces?: any
    loading?: boolean
    error?: boolean
    requestPolicy: any
    selectedStartDate: any
    setSelectedStartDate: any
    selectedDuration: any
    setSelectedDuration: any
    minStartDate: any
    justification: any
    setJustification: any
    hasInvalidDates?: boolean
    invalidDatesMessage?: any
    item: any
}

export const CheckOutAdvanced: FC<CheckOutAdvancedProps> = (props) => {
    const {
        open,
        onClose,
        onSucces,
        loading,
        requestPolicy,
        selectedStartDate,
        setSelectedStartDate,
        minStartDate,
        selectedDuration,
        setSelectedDuration,
        justification,
        setJustification,
        hasInvalidDates,
        invalidDatesMessage,
        item,
    } = props
    const { t } = useTranslation()

    const classes = useStyles()
    const dialogClasses = useDialogStyles()
    const theme = useTheme()

    const { data: disbaledSlots } = GetDisabledTimeSlots(
        item.id,
        moment().utc().add('days', 30).format(),
    )
    const [isMaxDurationSelected, setIsMaxDurationSelected] = useState<boolean>(
        false,
    )

    const attributeOrientation = useIsSmallScreen() ? 'vertical' : 'horizontal'
    const footerContent = (
        <Box display="flex" width="100%" padding="24px 32px">
            <Box minWidth="167px">
                <EidButton.DrawerActionButton
                    fontColor={theme?.palette?.common?.white}
                    bgColor={theme?.palette?.primary?.main}
                    width="100%"
                    onClick={onSucces}
                    loading={loading}
                    disabled={hasInvalidDates}
                >
                    <Box display="flex" alignItems="center">
                        <Icon
                            name="Check"
                            color={theme?.palette?.common?.white}
                        />
                    </Box>
                    <Box display="flex" alignItems="center" marginLeft="10px">
                        <Typography
                            style={{ color: theme?.palette?.common?.white }}
                        >
                            {t('CheckOut')}
                        </Typography>
                    </Box>
                </EidButton.DrawerActionButton>
            </Box>
        </Box>
    )

    const title = t('CheckOutAdvancedMode')

    const handleSelectMaxDuration = (e: any) => {
        setIsMaxDurationSelected(e.target.checked)
        if (e.target.checked) {
            setSelectedDuration(requestPolicy?.maximumValueInMinutes)
        } else {
            if (requestPolicy?.defaultValueInMinutes) {
                setSelectedDuration(requestPolicy?.defaultValueInMinutes)
            }
        }
    }

    useEffect(() => {
        if (requestPolicy) {
            setSelectedDuration(requestPolicy.defaultValueInMinutes)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [requestPolicy])

    return (
        <Dialog
            scroll={'body'}
            open={open}
            onClose={onClose}
            aria-labelledby={title}
            aria-describedby={title}
            classes={dialogClasses}
        >
            <Box className={classes.modalHeader}>
                <Typography className={classes.title}>{title}</Typography>

                <Box className={classes.closeIcon}>
                    <IconButton onClick={onClose} size="large">
                        <Icon name="Close" color="#959598" />
                    </IconButton>
                </Box>
            </Box>

            <Box className={classes.sectionHeader}>{t('SetDuration')}</Box>
            <Box className={classes.body}>
                <Box display="flex">
                    <Box flex="1">
                        <ItemDetails.Attribute
                            label={t('DefaultAccessDurationInMinutes')}
                            labelProps={{ marginRight: '10px' }}
                            value={requestPolicy?.defaultValueInMinutes}
                            orientation={attributeOrientation}
                            valueProps={overflowStyleProps}
                        />
                        <ItemDetails.Attribute
                            label={t('MaxAccessDurationInMinutes')}
                            labelProps={{ marginRight: '10px' }}
                            value={requestPolicy?.maximumValueInMinutes}
                            orientation={attributeOrientation}
                            valueProps={overflowStyleProps}
                        />
                    </Box>
                    <Box flex="1">
                        <ItemDetails.Attribute
                            label={t('Justification')}
                            labelProps={{ marginRight: '10px' }}
                            value={
                                <TextInput
                                    handleChange={setJustification}
                                    value={justification}
                                    label={null}
                                    placeholder={null}
                                />
                            }
                            orientation={'vertical'}
                            valueProps={{
                                style: { marginBottom: '-10px' },
                                overflowStyleProps,
                            }}
                        />
                    </Box>
                </Box>
                <Box>
                    <ItemDetails.Attribute
                        label={t('SetDuration')}
                        labelProps={{
                            style: {
                                marginRight: '10px',
                                alignSelf: 'center',
                                minWidth: '100px',
                            },
                        }}
                        value={
                            <>
                                <Box display="flex">
                                    <TimeDurationControl
                                        disabled={
                                            !requestPolicy?.isEndDateSelectable ||
                                            isMaxDurationSelected
                                        }
                                        selectedStartDate={selectedStartDate}
                                        setSelectedStartDate={
                                            setSelectedStartDate
                                        }
                                        selectedDuration={selectedDuration}
                                        setSelectedDuration={
                                            setSelectedDuration
                                        }
                                        minStartDate={minStartDate}
                                        maximumDuration={
                                            requestPolicy?.maximumValueInMinutes
                                        }
                                        customTimeControl={true}
                                        disabledTimeSlots={disbaledSlots}
                                    />
                                    {requestPolicy?.maximumValueInMinutes && (
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    edge="start"
                                                    color="primary"
                                                    onChange={(e) => {
                                                        handleSelectMaxDuration(
                                                            e,
                                                        )
                                                    }}
                                                    checked={
                                                        isMaxDurationSelected
                                                    }
                                                    style={{
                                                        marginLeft: '8px',
                                                        padding: '8px',
                                                    }}
                                                />
                                            }
                                            label={t('Common_MaxDuration')}
                                            className={
                                                classes.maxDurationCheckbox
                                            }
                                        />
                                    )}
                                </Box>

                                {hasInvalidDates && invalidDatesMessage && (
                                    <Box
                                        className={classes.invalidDatesMessage}
                                    >
                                        {invalidDatesMessage}
                                    </Box>
                                )}
                            </>
                        }
                        orientation={attributeOrientation}
                        valueProps={overflowStyleProps}
                    />
                </Box>
            </Box>

            <Box className={classes.footer}>{footerContent}</Box>
        </Dialog>
    );
}
