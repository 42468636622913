import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { PreApprovedItemsAccessTile } from '../AccessTile'
import { PreApprovedItemWrapper } from '../WrapperForPreApprovedAccessItem'

export type PreApprovedMembershipAccessProps = {
    personResourceAssignments: any
    resource: any
    resourceType: any
    handleViewRequest: () => void
    title: string
    manageBtnUrl: string
}

export const PreApprovedMembershipAccess: FC<
    PreApprovedMembershipAccessProps
> = (props: PreApprovedMembershipAccessProps) => {
    const {
        personResourceAssignments,
        resource,
        resourceType,
        handleViewRequest,
        title,
        manageBtnUrl,
    } = props
    const { t } = useTranslation()

    return (
        <PreApprovedItemWrapper
            title={title}
            itemCount={
                personResourceAssignments?.length
                    ? personResourceAssignments.length
                    : 0
            }
        >
            {personResourceAssignments?.length > 0 &&
                personResourceAssignments.map(
                    (assignment: any, index: number) => (
                        <PreApprovedItemsAccessTile
                            handleViewRequest={handleViewRequest}
                            key={`item-pre-approved-${index}`}
                            assignment={assignment}
                            resource={resource}
                            resourceType={resourceType}
                            type={
                                !assignment?.isPreApproved &&
                                assignment?.isAssigned
                                    ? 'membership'
                                    : 'preapprovedActivate'
                            }
                            accessName={
                                assignment?.resourceAssignment
                                    ?.assigneePreviewName ?? '-'
                            }
                            accessTypeLabel={
                                !assignment?.isPreApproved &&
                                assignment?.isAssigned
                                    ? t('Common_Membership')
                                    : t('Common_PreApproved')
                            }
                            canActivateNow={assignment?.canActivateNow}
                            manageBtnUrl={manageBtnUrl}
                        />
                    ),
                )}
        </PreApprovedItemWrapper>
    )
}
