import { useState } from 'react'
import { Box } from '@mui/material'
import { useIsSmallScreen } from 'packages/core'
import { Pagination, ThresholdSearchInput } from 'packages/eid-ui'
import { useTranslation } from 'react-i18next'
import { TableGrid } from 'components'
import { useBusinessRoleApplicationRolesGranted } from 'hooks'
import { CheckedIcon } from 'packages/eid-icons'

export const ApplicationRolesGranted = ({
    businessRole,
    differentiationValue,
}) => {
    const take = 10
    const isSmallScreen = useIsSmallScreen()

    const { t } = useTranslation()
    const [page, setPage] = useState(1)
    const [perPageItems, setPerPageItems] = useState(take)
    const [searchKey, setSearchKey] = useState('')

    const handlePageChange = (_, value) => {
        setPage(value)
    }
    const handlePageSelection = (value) => {
        setPage(value)
    }
    const handleItemsPerPageChange = (value) => {
        setPage(1)
        setPerPageItems(value)
    }

    const handleSearchChange = (value) => {
        setPage(1)
        setSearchKey(value)
    }

    const { latestData } = useBusinessRoleApplicationRolesGranted(
        businessRole.id,
        differentiationValue?.id,
        (page - 1) * perPageItems,
        perPageItems,
        searchKey && encodeURIComponent(searchKey),
    )
    const data = latestData ? latestData.data : undefined

    const isLoading = !Boolean(latestData)

    const numberOfPages = latestData
        ? Math.ceil(latestData.totalCount / perPageItems)
        : 0

    const attributes = [
        {
            label: '',
            resolve: () => <CheckedIcon />,
        },
        {
            label: t('Common_FriendlyName'),
            resolve: (item) => <> {item.friendlyName}</>,
        },
        {
            name: 'resourceSystemFriendlyName',
            label: t('BusinessRoles_ResourceSystem'),
        },
    ]

    return (
        <>
            <Box
                width="100%"
                display="flex"
                alignItems="center"
                justifyContent="flex-start"
                padding="16px 32px 16px 32px"
                style={{ backgroundColor: '#ebebed' }}
            >
                <Box width={!isSmallScreen ? '308px' : '200px'}>
                    <ThresholdSearchInput
                        placeholder={'Common_Search'}
                        handleSearch={handleSearchChange}
                    />
                </Box>
            </Box>
            <TableGrid
                loading={isLoading}
                data={data}
                attributes={attributes}
            />

            {!isLoading && (
                <Box
                    padding={!isSmallScreen ? '20px 32px' : '16px'}
                    display="flex"
                    alignItems="center"
                    justifyContent={!isSmallScreen ? 'flex-start' : 'center'}
                >
                    <Pagination
                        count={numberOfPages}
                        size={!isSmallScreen ? 'large' : 'small'}
                        page={page}
                        onChange={handlePageChange}
                        totalCount={latestData?.totalCount}
                        onPageSelection={handlePageSelection}
                        itemsPerPage={perPageItems}
                        onItemsPerPageChange={handleItemsPerPageChange}
                        showPageSelection={true}
                        showItemsPerPageSelection
                        showCountStat
                    />
                </Box>
            )}
        </>
    )
}
