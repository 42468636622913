import { useQuery, usePaginatedQuery } from 'react-query'
import { useAxios, useGetControlsAccess } from 'packages/core'

const APPLICATION_ROLE_KEY_PREFIX = 'APPLICATION_ROLE'

export const useApplicationProcesses = (targetPersonId) => {
    const callApi = useAxios()
    const controls = useGetControlsAccess()
    const hasAccessToSuggestedApplicationRolesControl =
        controls.findIndex(
            (c) => c.name === 'ITShop-SuggestedApplicationRoles-Control',
        ) >= 0

    return useQuery(
        `${APPLICATION_ROLE_KEY_PREFIX}_PROCESSES_${targetPersonId}`,
        () =>
            callApi({
                method: 'GET',
                url: `/api/applicationRoles/applicationProcesses?targetPersonId=${targetPersonId}&showSuggested=${!hasAccessToSuggestedApplicationRolesControl}`,
            }).then((data) => data.data),
        {
            enabled: Boolean(targetPersonId),
            staleTime: 1800000,
        },
    )
}

export const useApplicationRole = (id, targetPersonId) => {
    const callApi = useAxios()

    return useQuery(
        [APPLICATION_ROLE_KEY_PREFIX, id, targetPersonId],
        () =>
            callApi({
                method: 'GET',
                url: `/api/applicationRoles/${id}?targetPersonId=${targetPersonId}`,
            }).then((data) => data.data),
        {
            enabled: Boolean(id),
        },
    )
}

export const useApplicationRoleManageAccess = (id, targetPersonId) => {
    const callApi = useAxios()

    return useQuery(
        [APPLICATION_ROLE_KEY_PREFIX, id, targetPersonId],
        () =>
            callApi({
                method: 'GET',
                url: `/api/applicationRoles/manageAccess/${id}?targetPersonId=${targetPersonId}`,
            }).then((data) => data.data),
        {
            enabled: Boolean(id),
        },
    )
}


export const useApplicationRoleOwnersAndApprovers = (id) => {
    const callApi = useAxios()
    return useQuery(
        [`${APPLICATION_ROLE_KEY_PREFIX}_OWNERS_APPROVERS`, id],
        () =>
            callApi({
                method: 'GET',
                url: `/api/applicationRoles/people?roleId=${id}`,
            }).then((data) => data.data),
        {
            enabled: Boolean(id),
        },
    )
}

export const useApplicationRoleLocalFunctions = (
    id,
    skip,
    take = 10,
    search,
) => {
    const callApi = useAxios()
    return usePaginatedQuery(
        [
            `${APPLICATION_ROLE_KEY_PREFIX}_LOCAL_FUNCTIONS`,
            id,
            skip,
            take,
            search,
        ],
        () =>
            callApi({
                method: 'GET',
                url:
                    `/api/businessFunctions/localFunctions?roleId=${id}&skip=${skip}&take=${take}` +
                    (search ? `&searchTerm=${search}` : ''),
            }),
        {
            enabled: Boolean(id),
        },
    )
}

export const useCheckApplicationRoleAccess = (id, targetPersonId) => {
    const callApi = useAxios()
    return useQuery(
        [`${APPLICATION_ROLE_KEY_PREFIX}_ACCESS`, id, targetPersonId],
        () =>
            callApi({
                method: 'GET',
                url: `/api/applicationRoles/checkAssigmentStatus/${id}/${targetPersonId}`,
            }).then((data) => data.data[0]),
        {
            enabled: Boolean(id) && Boolean(targetPersonId),
        },
    )
}

export const useApplicationRoleApplications = (id, skip, take = 10, search) => {
    const callApi = useAxios()

    return usePaginatedQuery(
        [`${APPLICATION_ROLE_KEY_PREFIX}_APPLICATIONS`, id, skip, take, search],
        () =>
            callApi({
                method: 'GET',
                url:
                    `/api/ProtectedAppResources/linkedApplications?applicationRoleId=${id}&skip=${skip}&take=${take}` +
                    (search ? `&searchTerm=${search}` : ''),
            }),
        {
            enabled: Boolean(id),
        },
    )
}

export const useApplicationRoleBr = (
    targetPersonId,
    resourceId,
    resourceTypeId,
) => {
    const callApi = useAxios()
    return useQuery(
        [
            APPLICATION_ROLE_KEY_PREFIX,
            'Business_Request',
            targetPersonId,
            resourceId,
            resourceTypeId,
        ],
        () =>
            callApi({
                method: 'GET',
                url: `/api/applicationRoles/businessRequestItemAssignmentDetails?targetPersonId=${targetPersonId}&resourceId=${resourceId}&resourceTypeId=${resourceTypeId}`,
            }).then((data) => data.data),
        {
            enabled:
                Boolean(targetPersonId) &&
                Boolean(resourceId) &&
                Boolean(resourceTypeId),
        },
    )
}
