import StyledSearchInput from './StyledSearchInput'
import { useTranslation } from 'react-i18next'
import { useState, useEffect } from 'react'
import { useDebounce } from 'packages/core'

const ThresholdSearchInput = ({
    sx = {},
    handleSearch,
    placeholder, 
    minCharacterLength = 3,
    ...rest
}) => {
    const { t } = useTranslation()

    const [searchText, setSearchText] = useState('')
    const [previousValidLength, setPreviousValidLength] = useState(0)
    const debouncedValue = useDebounce(searchText)

    const handleInputChange = (event) => {
        const { value } = event.target
        setSearchText(value)
    }

    useEffect(() => {
        if (
            (!debouncedValue && previousValidLength >= minCharacterLength) ||
            debouncedValue.length >= minCharacterLength
        ) {
            handleSearch(debouncedValue)
            setPreviousValidLength(debouncedValue.length)
        }
    }, [debouncedValue])

    return (
        <StyledSearchInput
            sx={sx}
            fullWidth
            type="text"
            placeholder={t(`${placeholder}`)}
            value={searchText}
            onChange={handleInputChange}
            {...rest} 
        />
    )
}

export default ThresholdSearchInput
