import { Box, Dialog, useTheme, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import config from 'config'
import { Icon } from 'packages/eid-icons'
import { Avatar, Divider, IconButton, Loader, ThresholdSearchInput } from 'packages/eid-ui'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import { useDebounce, useSwrApiGet } from 'packages/core'
import { useAppState } from 'appContext'

const useModalStyles = (topPosition, rightPosition) =>
    makeStyles((theme) => ({
        root: {
            '& .MuiDialog-paper': {
                maxWidth: 'unset',
                position: 'absolute !important',
                left: `${(rightPosition - 16) / 10}rem`,
                top: `${(topPosition - 200) / 10}rem`,
                borderRadius: '0.8rem',
                boxShadow:
                    'inset 0 0 0 1px #fbfbfd, 0 0 0 0.4rem rgba(48, 127, 193, 0.05), 0 0.4rem 1.6rem 0.4rem rgba(0, 0, 0, 0.05), 0 0.8rem 3.2rem 0.4rem rgba(0, 0, 0, 0.05)',
            },
        },
        modal: {
            backgroundColor: '#fbfbfd',
            minWidth: '62.7rem',
            minHeight: '38.1rem',
            padding: '22px 24px 23px',
            borderRadius: '0.8rem',
            '&:focus': {
                outline: 'none',
            },
        },
        modalHeader: {
            display: 'flex',
            justifyContent: 'space-between',
            minHeight: '2rem',
        },
        closeIcon: {
            display: 'flex',
            height: '10px',
        },

        title: {
            fontSize: '1.6rem',
            fontWeight: 'bold',
            color: theme?.palette.common.black,
        },
        searchResults: {
            width: '579px',
            height: '224px',
            margin: '1.6rem 0 0',
            padding: '9.5px 7px 6px 6px',
            borderRadius: '5px',
            boxShadow: '0 4px 6px 0 rgba(0, 0, 0, 0.07)',
            border: 'solid 1px #d4d4d8',
            backgroundColor: theme?.palette.common.white,
            overflow: 'auto',
        },
        personOption: {
            cursor: 'pointer',
            marginBottom: '3px',
            '& div': {
                color: '#919193',
            },
            '&:hover': {
                backgroundColor: '#f5f6f8',
                '& div .friendly-name-text': {
                    color: theme?.palette.primary.main,
                },
                '& div .login-text': {
                    color: '#3b454d',
                },
            },
        },
        lineGlow: {
            height: '1px',
            opacity: '0.25',
            borderStyle: 'solid',
            borderWidth: '1px',
            borderImageSource:
                'linear-gradient(to right, rgba(1, 174, 143, 0), #04ab93 7%, #2c83bd 91%, rgba(48, 127, 193, 0))',
            borderImageSlice: '1',
        },
    }))
const PersonOption = ({
    option,
    labelProp = 'friendlyName',
    emailProp = 'email',
    imageUrlProp = 'imageThumbUrl',
    cdnUrl,
    disableImage = false,
    onSelect,
}) => {
    const classes = useModalStyles()()
    return (
        <Box
            className={classes.personOption}
            display="flex"
            alignItems="center"
            padding="0.8rem"
        >
            {!disableImage && (
                <Box component="span" paddingRight="2rem">
                    <Avatar src={`${cdnUrl}${option[imageUrlProp]}`} />
                </Box>
            )}
            <Box
                style={{ display: 'flex' }}
                onClick={(e) => onSelect(e, option)}
            >
                <Typography
                    display="block"
                    className="friendly-name-text"
                    style={{
                        fontSize: '1.6rem',
                        fontWeight: 'normal',
                        width: '22rem',
                    }}
                    noWrap={true}
                >
                    {option[labelProp]}
                </Typography>
                <Typography
                    display="block"
                    style={{
                        fontSize: '1.2rem',
                        width: '25.6rem',
                    }}
                    noWrap={true}
                    className="login-text"
                    title={option[emailProp]}
                >
                    {option[emailProp]}
                </Typography>
            </Box>
        </Box>
    )
}
const searchFields = [
    {
        name: 'First Name',
        placeholder: 'FirstName',
        queryParam: 'FirstName',
    },
    {
        name: 'Last Name',
        placeholder: 'LastName',
        queryParam: 'LastName',
    },
    {
        name: 'Email',
        placeholder: 'Email',
        queryParam: 'Email',
    },
    {
        name: 'Login',
        placeholder: 'Login',
        queryParam: 'Login',
    },
]
export const AdvancePeopleFinder = (props) => {
    const {
        open,
        onClose,
        onChange,
        dedupingInterval = 2000,
        debounceInterval = 300,
        url,
        topPosition,
        rightPosition,
    } = props
    const theme = useTheme()
    const [{ headerHeight, subHeaderHeight }] = useAppState()
    const initialValues = searchFields.reduce((acc, field) => {
        acc[field.queryParam] = '' // Set each queryParam as a key with an empty string value
        return acc
    }, {})

    const inputStyles = {
        '& MuiInputBase-root': {
            height: '3.2rem',
            marginTop: theme.spacing(3),
        },
        input: {
            borderRadius: '0.5rem',
            position: 'relative',
            backgroundColor: `${theme?.palette?.background?.paper} !important`,
            marginBottom: '1.6rem !important',
            border: '0.1rem solid #d8d8dd',
            fontSize: '1.4rem',
            padding: '0.5rem 0.8rem 0.5rem 1.2rem',
            width: '11rem',
            lineHeight: 1.25,
            boxShadow: '0 0.4rem 0.6rem -0.2rem rgba(0, 0, 0, 0.1)',
            '&:hover, &:focus': {
                borderColor: theme.palette.primary.main,
                backgroundColor: theme.palette.common.white,
            },
        },
        '&.focused': {
            backgroundColor: theme.palette.common.white,
        },
        error: {
            '& input, & textarea': {
                border: '0.1rem solid #f44336 !important',
            },
        },
    }

    const [searchTerms, setSearchTerms] = useState(initialValues)
    const [callEndpoint, setCallEndpoint] = useState(false)
    const debouncedValue = useDebounce(searchTerms, debounceInterval)

    const checkIfAnyValueExists = (obj) => {
        for (const key in obj) {
            if (obj[key] !== '') {
                return true // If any key has a non-empty value, return true
            }
        }
        return false // If all keys are empty, return false
    }
    const queryParamNames = []
    const queryParamValues = []
    const queryParamTypes = []
    const queryStringParts = []
    if (checkIfAnyValueExists(debouncedValue)) {
        for (const key in debouncedValue) {
            if (debouncedValue[key] !== '') {
                queryParamNames.push(key)
                queryParamValues.push(debouncedValue[key].replace(',', ' '))
                queryParamTypes.push(typeof debouncedValue[key])
            }
        }
    }
    if (
        queryParamNames.length > 0 &&
        queryParamValues.length === queryParamNames.length
    ) {
        queryStringParts.push(
            `advancedSearchParamNames=${queryParamNames.join(',')}`,
        )
        queryStringParts.push(
            `advancedSearchParamValues=${queryParamValues.join(',')}`,
        )
        queryStringParts.push(
            `advancedSearchParamTypes=${queryParamTypes.join(',')}`,
        )
    }
    const queryString = queryStringParts.join('&')

    const urlWithQs = queryString ? `${url}?${queryString}` : url

    const { data, isValidating } = useSwrApiGet(
        () => (callEndpoint && open ? urlWithQs : false),
        {
            dedupingInterval,
        },
    )
    useEffect(() => {
        setCallEndpoint(true)
    }, [debouncedValue])
    const getModalTopPosition = () => {
        const topHeadersHeight = subHeaderHeight + headerHeight
        const midOfInputFromTop = topPosition + 25
        const halfOfPopupHeight = (23.18 * 16) / 2
        if (halfOfPopupHeight <= midOfInputFromTop - topHeadersHeight) {
            return midOfInputFromTop
        } else {
            const diff =
                midOfInputFromTop - topHeadersHeight - halfOfPopupHeight
            return midOfInputFromTop + Math.abs(diff) - 25
        }
    }
    const classes = useModalStyles(getModalTopPosition(), rightPosition)()
    const { t } = useTranslation()
    const onClosePopup = () => {
        setCallEndpoint(false)
        setSearchTerms(initialValues)
        onClose()
    }
    return (
        <>
            <Dialog
                scroll={'body'}
                open={open}
                onClose={onClose}
                classes={classes}
                BackdropProps={{ style: { opacity: '0.5' } }}
            >
                <Box className={classes.modal}>
                    <Box className={classes.modalHeader}>
                        <Typography className={classes.title}>
                            {t('AdvancedPersonFinder')}
                        </Typography>
                        {/* Commenting below close icon as it is not in zeplin design but maybe required in future. 
                        Code will be removed if there's no requirement in near future */}
                        {/* <Box className={classes.closeIcon}>
                            <IconButton onClick={() => onClosePopup()}>
                                <Icon name="Close" color="#959598" />
                            </IconButton>
                        </Box> */}
                    </Box>

                    <Box
                        style={{
                            display: 'flex',
                            flexWrap: 'wrap', // Ensures items wrap to the next line when space is limited
                            gap: '17px', // Adjust the gap between input fields
                            marginTop: '23px',
                        }}
                    >
                        {searchFields.map((field, i) => {
                            return (
                                <>
                                    <Box
                                        style={{ display: 'flex' }}
                                        key={i + field.queryParam}
                                    >
                                        <ThresholdSearchInput
                                            placeholder={t(field.placeholder)}
                                            handleSearch={(value) =>
                                                setSearchTerms((pre) => ({
                                                    ...pre,
                                                    [field.queryParam]: value,
                                                }))
                                            }
                                            sx={inputStyles}
                                        />
                                    </Box>
                                </>
                            )
                        })}
                    </Box>
                    <Divider color="#d8d8d8" />
                    <Box className={classes.searchResults}>
                        {isValidating && <Loader />}
                        {data?.data?.length === 0 && !isValidating ? (
                            <Box
                                style={{
                                    margin: '0px 0px',
                                    justifyContent: 'center',
                                    display: 'flex',
                                }}
                            >
                                {t('Common_NoDataFound')}
                            </Box>
                        ) : (
                            !isValidating &&
                            data?.data?.map((t, i) => {
                                return (
                                    <PersonOption
                                        option={t}
                                        cdnUrl={config.BASE_EID_URL}
                                        onSelect={(_, value) => {
                                            onChange(_, value)
                                            onClosePopup()
                                        }}
                                    />
                                )
                            })
                        )}
                    </Box>
                </Box>
                <div className={classes.lineGlow}></div>
            </Dialog>
        </>
    )
}
