import React, { useEffect, useState } from 'react'
import { Box, Typography, useTheme } from '@mui/material'
import {
    Drawer,
    Dropdown,
    Loader,
    ValidationMessage,
    ValidationMessages,
    WithCloseButton,
} from 'packages/eid-ui'
import { Icon } from 'packages/eid-icons'
import TextField from './TextField'
import { useTranslation } from 'react-i18next'
import {
    isDevelopmentEnv,
    isNilOrEmpty,
    isTestEnv,
    isWhitespace,
    useIsSmallScreen,
} from 'packages/core'
import CartItem from './CartItem'
import CartSubmissionMessageModal from 'containers/Cart/CartSubmissionMessageModal'
import {
    useCart,
    useDefaultLineManager,
    useEnvironment,
    useEvaluateCart,
    usePreviousEvaluationResults,
    useSubmitCart,
    useTargetPerson,
    useEidGlobalSetting,
    usePredefinedJustifications,
    useAllCarts,
} from 'hooks'
import ApproverSelection from './ApproverSelection'
import BusinessRequestTypes from './BusinessRequestTypes'
import DueDate from './DueDate'
import { MyContainer, MyTypography, useStyles } from './styles'
import EmptyCartButton from './EmptyCartButton'
import useSubcomponents from 'useSubcomponents'
import { CartViolations } from './CartViolations'
import CartButton from './CartButton'
import {
    ITEM_COMMENT_MAX_LENGTH,
    ITEM_COMMENT_MIN_LENGTH,
} from './CartItemComment'
import { AllCartsDropdown } from 'components/Layout/CartDropdown'

const CART_COMMENT_MAX_LENGTH = 500
const BUSINESS_REQUEST_NAME_MAX_LENGTH = 500

const initialCartState = {
    selectedApprover: null,
    businessRequestType: null,
    cartDescription: '',
    cartComment: '',
    dueDate: null,
    acknowledged: false,
    showValidationErrors: false,
}

const showCartBusinessRequestType = 'ITShop_Cart_Show_BusinessRequestType'

const shouldShowEvaluationPrompt = (cart) => {
    return cart.cartItems.length > 0 && cart.requiresEvaluation
}

const shouldShowSubmitData = (cart) =>
    cart.cartItems.length > 0 && !cart.requiresEvaluation

const getUniqueRisks = (risks) => {
    return risks.reduce((array, item) => {
        if (!array.some((r) => r.id === item.id)) {
            array.push(item)
        }
        return array
    }, [])
}

const getUniqueRisksForItem = (risks, itemId) =>
    risks
        .filter((ri) => ri.cartItemId === itemId)
        .reduce((array, item) => {
            if (
                !array.some(
                    (r) =>
                        r.id === item.id &&
                        r.riskReason_ResourceFriendlyName ===
                            item.riskReason_ResourceFriendlyName,
                )
            ) {
                array.push(item)
            }
            return array
        }, [])

const ShoppingCartDrawer = React.forwardRef(({ open, toggleDrawer }, ref) => {
    const {
        hasAccessToSeeApproverControl,
        hasAccessToCartDueDate,
        hasAccessToSeeBusinessRequestTypesControl,
    } = useSubcomponents()

    const { data: eidCartSettings, isLoading: isLoadingCartSetting } =
        useEidGlobalSetting()

    let showLineManager = false
    let showRequestTypes = false

    if (!isLoadingCartSetting && eidCartSettings) {
        const showLineManagerSetting =
            eidCartSettings.find(
                (x) => x.name === 'ITShop_Cart_Show_LineManager',
            )?.value === 'true'

        const showRequestTyeSetting =
            eidCartSettings.find((x) => x.name === showCartBusinessRequestType)
                ?.value === 'true'

        showLineManager =
            showLineManagerSetting && hasAccessToSeeApproverControl
        showRequestTypes =
            showRequestTyeSetting && hasAccessToSeeBusinessRequestTypesControl
    }

    const classes = useStyles()
    const { data: predefinedJustification } = usePredefinedJustifications()
    const { data: env } = useEnvironment()
    const isDevOrTest = isDevelopmentEnv(env) || isTestEnv(env)
    const { hasMultipleCarts, data: multiCarts } = useAllCarts()
    const { t } = useTranslation()

    const [targetPerson] = useTargetPerson()

    const theme = useTheme()
    const isSmallScreen = useIsSmallScreen()

    const { data: cart } = useCart()

    const [evaluateShoppingCart, { isLoading: evaluating }] = useEvaluateCart()
    const { data: evaluateCartData, isFetching: isLoadingEvaluation } =
        usePreviousEvaluationResults()

    const [
        submitCart,
        { data: submitResponse, isLoading: submittingCart, error: submitError },
    ] = useSubmitCart()

    const { data: lineManager, isLoading: loadingLineManager } =
        useDefaultLineManager(targetPerson.id, showLineManager)

    const [
        {
            selectedApprover,
            businessRequestType,
            cartDescription,
            cartComment,
            dueDate,
            acknowledged,
            showValidationErrors,
        },
        setCartState,
    ] = useState(initialCartState)

    const setShowValidationErrors = (newShowValidationErrors) =>
        setCartState((prev) => ({
            ...prev,
            showValidationErrors: newShowValidationErrors,
        }))

    const setAcknowledged = (newAcknowledged) =>
        setCartState((prev) => ({ ...prev, acknowledged: newAcknowledged }))

    const setSelectedApprover = (approver) =>
        setCartState((prev) => ({
            ...prev,
            selectedApprover: approver,
        }))

    useEffect(() => {
        setCartState(initialCartState)
    }, [open])

    const setBusinessRequestType = (brType) =>
        setCartState((prev) => ({ ...prev, businessRequestType: brType }))

    const setCartDescription = (description) =>
        setCartState((prev) => ({ ...prev, cartDescription: description }))

    const setCartComment = (comment) =>
        setCartState((prev) => ({ ...prev, cartComment: comment }))

    const setCartDueDate = (dueDate) =>
        setCartState((prev) => ({ ...prev, dueDate: dueDate }))

    const [cartItemsComment, setCartItemsComment] = useState({})

    const [toggle, setToggle] = useState(false)

    const closeCart = () => {
        toggleDrawer()
        setCartState(initialCartState)
    }

    const [submissionMessage, setSubmissionMessage] = useState({
        data: null,
        open: false,
        error: false,
    })

    useEffect(() => {
        if (submitResponse) {
            setSubmissionMessage({
                data: submitResponse.data,
                open: true,
                error: false,
            })
            closeCart(!toggle)
        }
        if (submitError) {
            setSubmissionMessage({
                data: null,
                open: true,
                error: true,
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [submitResponse, submitError])

    if (!cart) {
        return isSmallScreen ? (
            open && <Loader />
        ) : (
            <Drawer
                width="768px"
                open={open}
                toggleDrawer={closeCart}
                closeOnOutsideClick
                closeLabel={t('Common_Close')}
            >
                <Loader />
            </Drawer>
        )
    }

    const isCartItemsCommentInvalid = () => {
        try {
            if (ITEM_COMMENT_MIN_LENGTH > 0 && isNilOrEmpty(cartItemsComment)) {
                return true
            }

            let invalidComments = Object.keys(cartItemsComment).filter(
                (itemComment) => {
                    const isPredefinedComment = Boolean(
                        predefinedJustification?.find(
                            (x) => x.value === cartItemsComment[itemComment],
                        ),
                    )
                    if (
                        (cartItemsComment[itemComment].length >
                            ITEM_COMMENT_MAX_LENGTH ||
                            isWhitespace(cartItemsComment[itemComment]) ||
                            cartItemsComment[itemComment].length <
                                ITEM_COMMENT_MIN_LENGTH) &&
                        !isPredefinedComment
                    ) {
                        return itemComment
                    }
                    return null
                },
            )
            invalidComments = invalidComments?.filter((x) => x)
            return invalidComments?.length > 0 ? true : false
        } catch (err) {
            console.log('Justification', err)
            return false
        }
    }

    const isBrNameInvalid =
        !cartDescription ||
        cartDescription.length > BUSINESS_REQUEST_NAME_MAX_LENGTH ||
        isWhitespace(cartDescription)

    const isCartCommentInvalid =
        cartComment?.length > CART_COMMENT_MAX_LENGTH ||
        isWhitespace(cartComment)

    const isViolationsNotAcknowledged =
        evaluateCartData && evaluateCartData.risks.length > 0 && !acknowledged

    const isJustificationCommentMissing =
        evaluateCartData &&
        evaluateCartData.risks.length > 0 &&
        cart.cartItems.filter(
            (c) =>
                evaluateCartData.risks.filter((ri) => ri.cartItemId === c.id)
                    .length > 0 && !Boolean(c.comment),
        ).length > 0

    const notSubmittable =
        isBrNameInvalid ||
        isCartCommentInvalid ||
        loadingLineManager ||
        isCartItemsCommentInvalid() ||
        evaluating ||
        submittingCart ||
        isViolationsNotAcknowledged ||
        isJustificationCommentMissing ||
        isLoadingEvaluation

    const isValidationErrors =
        isBrNameInvalid ||
        isCartCommentInvalid ||
        isCartItemsCommentInvalid() ||
        isViolationsNotAcknowledged ||
        isJustificationCommentMissing

    const handleSubmit = () => {
        if (notSubmittable) {
            setShowValidationErrors(true)
        }

        if (submittingCart || notSubmittable) return

        const submissionRequest = {
            businessRequestName: cartDescription,
            comment: cartComment ? cartComment : null,
            targetPersonId: targetPerson.id,
            dueDate: dueDate,
        }

        if (showLineManager) {
            submissionRequest.approvers =
                selectedApprover &&
                (!lineManager || selectedApprover.id !== lineManager.id)
                    ? [selectedApprover.id]
                    : []
        }

        if (isDevOrTest && businessRequestType) {
            submissionRequest.businessRequestTypeId = businessRequestType.id
        }
        if (
            evaluateCartData &&
            evaluateCartData.risks.length > 0 &&
            acknowledged
        ) {
            submissionRequest.isAcknowledged = true
            submissionRequest.isRiskViolation = true
        }
        submitCart(submissionRequest)
    }

    return (
        <>
            {cart?.cartItems?.length ? (
                <Drawer
                    width="768px"
                    open={open}
                    toggleDrawer={closeCart}
                    closeOnOutsideClick
                    className={classes.drawer}
                    height="100%"
                    top={56}
                >
                    <WithCloseButton
                        iconPosition={{
                            right: '18px',
                            top: '50%',
                        }}
                        iconColor="rgb(139, 144, 154)"
                        onClose={closeCart}
                    >
                        <Box className={classes.roleReceiver}>
                            <MyTypography
                                align="left"
                                display="block"
                                style={{
                                    wordBreak: 'break-word',
                                    fontSize: '12px',
                                    color: theme?.palette?.grey?.[900],
                                }}
                            >
                                {t('Common_RoleReceiver')}
                            </MyTypography>
                            <MyTypography
                                style={{
                                    wordBreak: 'break-word',
                                    fontSize: '24px',
                                    lineHeight: 1.25,
                                    fontWeight: 600,
                                }}
                            >
                                {targetPerson.friendlyName}
                            </MyTypography>
                        </Box>
                    </WithCloseButton>
                    <Box className={classes.roleReceiverDivider} />
                    <MyContainer>
                        {cart.cartItems.map((item, index) => (
                            <CartItem
                                onToggle={() => setToggle(!toggle)}
                                item={item}
                                index={index + 1}
                                key={index}
                                onCommentChange={(id, comment) => {
                                    const newComments = {
                                        ...cartItemsComment,
                                    }
                                    newComments[id] = comment
                                    setCartItemsComment(newComments)
                                }}
                                risks={
                                    evaluateCartData &&
                                    getUniqueRisksForItem(
                                        evaluateCartData.risks,
                                        item.id,
                                    )
                                }
                            />
                        ))}
                    </MyContainer>

                    {cart.cartItems.length > 0 &&
                        evaluateCartData &&
                        evaluateCartData.risks &&
                        evaluateCartData.risks.length > 0 && (
                            <Box className={classes.box}>
                                <Box marginBottom="24px">
                                    <CartViolations
                                        risks={getUniqueRisks(
                                            evaluateCartData.risks,
                                        )}
                                        acknowledged={acknowledged}
                                        setAcknowledged={setAcknowledged}
                                    />
                                </Box>
                            </Box>
                        )}

                    {shouldShowEvaluationPrompt(cart) && (
                        <Box className={classes.box}>
                            <Box display="flex" justifyContent="space-between">
                                <CartButton
                                    icon={
                                        <Icon
                                            width={20}
                                            height={20}
                                            name="Tasks"
                                            color={
                                                theme?.palette?.common?.white
                                            }
                                        />
                                    }
                                    bgcolor={theme?.palette?.primary?.main}
                                    label={t('Common_EvaluateRequest')}
                                    loading={evaluating}
                                    disabled={evaluating}
                                    onClick={() => {
                                        setAcknowledged(false)
                                        evaluateShoppingCart()
                                    }}
                                />
                                <EmptyCartButton />
                            </Box>
                        </Box>
                    )}

                    {shouldShowSubmitData(cart) && showLineManager && (
                        <Box
                            data-protectedsubcomponent={`ITShop_Cart_Show_LineManager AND ${hasAccessToSeeApproverControl}`}
                            className={classes.lineManagerContainer}
                        >
                            <Box
                                className={classes.lineManager}
                                style={{ maxWidth: '100%' }}
                            >
                                <ApproverSelection
                                    lineManager={lineManager}
                                    loadingLineManager={loadingLineManager}
                                    selectedApprover={selectedApprover}
                                    setSelectedApprover={setSelectedApprover}
                                />
                            </Box>
                        </Box>
                    )}

                    {showRequestTypes && shouldShowSubmitData(cart) && (
                        <Box
                            data-protectedsubcomponent={`EidSetting-${showCartBusinessRequestType} AND ${hasAccessToSeeBusinessRequestTypesControl}`}
                            className={classes.lineManagerContainer}
                        >
                            <Box className={classes.lineManager}>
                                <BusinessRequestTypes
                                    selectedRequestType={businessRequestType}
                                    setSelectedRequestType={
                                        setBusinessRequestType
                                    }
                                />
                            </Box>
                        </Box>
                    )}

                    {shouldShowSubmitData(cart) && (
                        <Box className={classes.box}>
                            <Box className={classes.formContainer}>
                                <Box className={classes.requestName}>
                                    <TextField
                                        required={true}
                                        fullWidth
                                        placeholder={t(
                                            'Common_EnterBusinessRequestName',
                                        )}
                                        maxCharacters={
                                            BUSINESS_REQUEST_NAME_MAX_LENGTH
                                        }
                                        margin="none"
                                        multiline
                                        maxRows={5}
                                        value={cartDescription}
                                        onChange={(e) =>
                                            setCartDescription(e.target.value)
                                        }
                                        style={{
                                            backgroundColor:
                                                theme?.palette?.background
                                                    ?.paper,
                                        }}
                                    />
                                </Box>
                                {hasAccessToCartDueDate && (
                                    <Box
                                        data-protectedsubcomponent={
                                            hasAccessToCartDueDate
                                        }
                                        className={classes.dueDate}
                                    >
                                        <DueDate
                                            dueDate={dueDate}
                                            setDueDate={setCartDueDate}
                                        />
                                    </Box>
                                )}
                            </Box>
                            <Box className={classes.cartComment}>
                                <TextField
                                    fullWidth
                                    multiline
                                    maxRows={5}
                                    rows={2}
                                    maxCharacters={CART_COMMENT_MAX_LENGTH}
                                    placeholder={t('Common_AddComment')}
                                    margin="none"
                                    value={cartComment}
                                    onChange={(e) =>
                                        setCartComment(e.target.value)
                                    }
                                    style={{
                                        backgroundColor:
                                            theme?.palette?.background?.paper,
                                    }}
                                />
                            </Box>

                            {showValidationErrors && isValidationErrors && (
                                <ValidationErrors
                                    isCartItemsCommentInvalid={isCartItemsCommentInvalid()}
                                    isJustificationCommentMissing={
                                        isJustificationCommentMissing
                                    }
                                    isViolationsNotAcknowledged={
                                        isViolationsNotAcknowledged
                                    }
                                    isBrNameInvalid={isBrNameInvalid}
                                    isCartCommentInvalid={isCartCommentInvalid}
                                />
                            )}
                            <Box
                                display="flex"
                                justifyContent="space-between"
                                style={
                                    showValidationErrors && isValidationErrors
                                        ? { marginTop: '12px' }
                                        : {}
                                }
                            >
                                <CartButton
                                    icon={
                                        <Icon
                                            width={20}
                                            height={20}
                                            name="SubmitCart"
                                            color={theme?.palette?.grey?.[900]}
                                        />
                                    }
                                    bgcolor={theme?.palette?.primary?.main}
                                    label={t('Common_Submit')}
                                    loading={submittingCart}
                                    onClick={handleSubmit}
                                />

                                <EmptyCartButton />
                            </Box>
                        </Box>
                    )}
                </Drawer>
            ) : hasMultipleCarts && multiCarts?.length ? (
                <AllCartsDropdown
                    ref={ref}
                    showMax={3}
                    open={open}
                    setOpen={closeCart}
                />
            ) : (
                <Dropdown
                    open={open}
                    rootStyles={{
                        paddingRight: '0px',
                        paddingTop: '0px',
                    }}
                    width="42rem"
                    listStyles={{
                        paddingBottom: '0px',
                        '& > ul': {
                            paddingBottom: '0px',
                            paddingTop: '0px',
                        },
                    }}
                    anchorEl={ref.current}
                    handleClose={closeCart}
                >
                    <Box marginTop="-8px">
                        <Box
                            display="flex"
                            padding="32px"
                            alignItems="center"
                            justifyContent="space-between"
                        >
                            <Box display="flex" alignItems="center">
                                <Icon name="EmptyCart" />
                                <Typography
                                    style={{
                                        marginLeft: '10px',
                                        fontSize: '14px',
                                    }}
                                >
                                    {t('EmptyShoppingCartMessage')}
                                </Typography>
                            </Box>
                            <Icon
                                name="Close"
                                color="rgb(139, 144, 154)"
                                onClick={closeCart}
                                style={{
                                    cursor: 'pointer',
                                    width: '12px',
                                    height: '12px',
                                }}
                            />
                        </Box>
                    </Box>
                </Dropdown>
            )}
            <CartSubmissionMessageModal
                submissionMessage={submissionMessage}
                setSubmissionMessage={setSubmissionMessage}
                successMessage={t('Common_CartSubmitted')}
                errorMessage={t('Common_CartSubmissionError')}
            />
        </>
    )
})

export default ShoppingCartDrawer

const validationMessages = {
    cartItemsComment: 'Common_InvalidCartItemComment',
    justificationComments: 'Common_JustificationRequiredOnItems',
    violationsAcknowledgement: 'Common_AcknowledgementRequired',
    businessRequestName: 'Common_RequestNameRequired',
    cartComment: 'Common_InvalidCartComment',
}

const ValidationErrors = ({
    isCartItemsCommentInvalid,
    isJustificationCommentMissing,
    isViolationsNotAcknowledged,
    isBrNameInvalid,
    isCartCommentInvalid,
}) => {
    const { t } = useTranslation()

    return (
        <ValidationMessages>
            {isCartItemsCommentInvalid && (
                <ValidationMessage
                    message={t(`${validationMessages['cartItemsComment']}`)}
                />
            )}

            {isJustificationCommentMissing && (
                <ValidationMessage
                    message={t(
                        `${validationMessages['justificationComments']}`,
                    )}
                />
            )}
            {isViolationsNotAcknowledged && (
                <ValidationMessage
                    message={t(
                        `${validationMessages['violationsAcknowledgement']}`,
                    )}
                />
            )}
            {isBrNameInvalid && (
                <ValidationMessage
                    message={t(`${validationMessages['businessRequestName']}`)}
                />
            )}

            {isCartCommentInvalid && (
                <ValidationMessage
                    message={t(`${validationMessages['cartComment']}`)}
                />
            )}
        </ValidationMessages>
    )
}
