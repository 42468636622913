import { InputBase, styled } from '@mui/material'

const StyledSearchInput = styled(InputBase)(({ theme }) => ({
    width: '100% !important',
    'label + &': {
        marginTop: theme.spacing(3),
    },
    input: {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: `${theme?.palette?.common?.white} !important`,
        border: 'solid 1px #ebebed',

        fontSize: 16,
        padding: '10px 12px',
        '&:focus': {
            boxShadow: ` 0 2px 4px 0 ${theme?.palette?.primary?.light}`,
            borderColor: theme?.palette?.primary?.main,
            backgroundColor: theme?.palette?.common?.white,
        },
    },

    focused: {
        backgroundColor: theme?.palette?.common?.white,
    },
}))

export default StyledSearchInput
